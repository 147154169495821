import React, { Component } from "react";
import PropTypes from "prop-types";
import { PrimaryProgressButton } from "@flow/buttons";
import 'bootstrap/dist/css/bootstrap.css';
import Form from "@rjsf/core"
import { withTranslation } from "react-i18next";

import Task from "../Task";
import TaskContext from "../TaskContext";

import { completePreviousTask } from "../../services/process";

const metaSchemaDraft06 = require("ajv/lib/refs/json-schema-draft-06.json");
class SimpleViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      isSaving: false,
      isSubmitting: false,
      signers: props.context.signers,
      signer: props.context.signers ? props.context.signers[0] : "",
      UBO: false,
      SANCTION: false,
      PEP: false,
      ORIGINOFFUNDS: false,
      text: ""
    }
  }

  setCheckbox(signer) {
    if (signer && Array.isArray(signer.fileRequests)) {
      signer.fileRequests.forEach(element => {
        let v = {}
        v[element.requestType] = element.isPending
        this.setState(v)
      });
    }
  }

  componentDidMount() {
    this.setCheckbox(this.state.signer)
  }


  async onFormSubmit(e, formData) {
    e.preventDefault()
    const { task, onComplete, close, updateCase, user } = this.props;
    this.setState({ isSubmitting: true })

    const dataToSubmit = {
      ...formData,
      updatedAt: new Date(),
      caseworker: {
        name: user.profile.name,
        username: user.profile.username,
        sub: user.profile.sub
      }
    }
    onComplete(task.taskId, dataToSubmit,
      () => {
        this.setState({ isSubmitting: false })
        close();
        updateCase();
      });
  }




  onSignersChange(e, signers) {
    e.preventDefault()
    const signer = signers.filter(signer => signer.name === e.target.value)[0]
    this.setState({ signer: signer })
    this.setCheckbox(signer)
  }



  handleCheckboxChange(evt, topic) {     
  }

  handleChange(evt, topic) {    
  }

  formatSentEmails({ emails }) {
    let arr = []

    emails.forEach(e => {
      const c = e.id
      const obj = {};
      obj[`${c}`] = {
        ...e
      }
      arr.push(obj)
    });

    return { messagesSent: arr }
  }


  render() {
    const { schema, context, task, contextMetaData, caseData: { data }, close } = this.props
    const { flowId } = data
const {uiSchema} = schema
    const { isSaving, isSubmitting } = this.state
    return (
      <Task>
        <Form
          schema={schema}
          uiSchema={uiSchema}
          formData={this.formData}
          onChange={e => this.handleChange(e)}
          ref={this.formRef}
          disabled={isSaving || isSubmitting}
          additionalMetaSchemas={[metaSchemaDraft06]}
          noHtml5Validate
        >
          <div className="task-handlers">
            <PrimaryProgressButton
              title="Complete"
              isLoading={isSubmitting || isSaving}
              onClick={async () => {
                this.setState({ isSubmitting: true })                 
                await completePreviousTask({ flowId, user: this.props.user, task })                 
                this.setState({ isSubmitting: false })
                close();
              }}
            >
              Complete
            </PrimaryProgressButton>
          </div>
          <div style={{ margin: "10px 20px 20px 20px", "minWidth": "350px", "maxWidth": "40%", height: "100%" }}>
            <TaskContext data={context} metadata={contextMetaData} />
          </div>
        </Form>
      </Task>
    )
  }
}


SimpleViewer.propTypes = {
  onComplete: PropTypes.func,
  onSave: PropTypes.func,
  close: PropTypes.func,
  updateCase: PropTypes.func,
  schema: PropTypes.shape({}).isRequired,
  context: PropTypes.shape({}).isRequired,
  task: PropTypes.shape({}).isRequired,
  caseData: PropTypes.shape({}),
  t: PropTypes.func
};

SimpleViewer.defaultProps = {
  onComplete: () => {
  },
  onSave: () => {
  },
  close: () => {
  },
  updateCase: () => {
  },
  user: {},
  caseData: null
};

export default withTranslation("inbox")(SimpleViewer);