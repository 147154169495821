import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types"; 
import {v4 as uuid} from 'uuid'
import { withTranslation } from "react-i18next";

import { FontStyles } from "@flow/style";
import {Collapsible, DownloadFileButton} from "@flow/components";

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  ${FontStyles.Small};

  > li {
    padding-bottom: ${(props) => (props.level > 0 ? 0 : "1.5em")};
    line-height: 20px;
  }
`;

const Title = styled.div`
  text-transform: capitalize;
  ${FontStyles.NormalStrong};
  padding-bottom: 0.4em;
`;

const KeyField = styled.span`
  display: inline-block;
  ::first-letter {
    text-transform: capitalize;
  }
`;

const ValueField = styled.span`
  overflow-wrap: break-word;
`;

const Header = styled.span`
  text-transform: capitalize;
`;

const mapTaskContext = (key, value, metadata, level, t) => {
  if (value === undefined || value === null) {
    return null;
  }
  const nextLevel = level + 1;
  const valueIsArray = Array.isArray(value);
  const valueIsObject = typeof value === "object";
  const valueIsBoolean = typeof value === "boolean";

  if (
    (valueIsArray && value.length < 1) ||
    (valueIsObject && Object.keys(value).length < 1)
  ) {
    return null;
  }

  const valueMetadata = metadata && key ? metadata[key] : null;

  if (valueIsArray) {
    const list = (
      <List level={level}>
        {value.map((el) => (
          <li key={uuid()}>
            <TaskContext
              data={el}
              metadata={valueMetadata}
              t={t}
              level={nextLevel}
            />
          </li>
        ))}
      </List>
    );

    if (level > 0) {
      return (
        <li key={uuid()}>
          <Collapsible header={<Header>{t(key)}</Header>} title={t(key)}>
            {list}
          </Collapsible>
        </li>
      );
    }

    return (
      <li key={uuid()}>
        <Title>{t(key)}</Title>
        {list}
      </li>
    );
  }

  if (valueIsObject) {
    if (level > 0) {
      return (
        <li key={uuid()}>
          <Collapsible header={<Header>{t(key)}</Header>} title={t(key)}>
            <TaskContext
              data={value}
              metadata={valueMetadata}
              t={t}
              level={nextLevel}
            />
          </Collapsible>
        </li>
      );
    }

    return (
      <li key={uuid()}>
        <Title>{t(key)}</Title>
        <TaskContext
          data={value}
          metadata={valueMetadata}
          t={t}
          level={nextLevel}
        />
      </li>
    );
  }

  if (valueIsBoolean) {
    const displayValue = value ? "yes" : "no";
    return (
      <li key={uuid()}>
        {key && <KeyField>{t(key)}:</KeyField>}
        <ValueField>{` ${t(displayValue)}`}</ValueField>
      </li>
    );
  }
  if (key && key.toLowerCase() === "storeid") {
    return (
      <li key={uuid()}>
        <DownloadFileButton fileId={value}/>
      </li>
    )
  }

  if (key && ["updatedat", "fodtdato", "birthdate", "lastupdate"].includes(key.toLowerCase())) {
    let date = value.replace("Z", "").split(".")[0].split("T")
    date = date[1] ? `${date[0]}, ${date[1]}` : date[0];

    return (
      <li key={uuid()}>
        {key && <KeyField>{t(key)}:</KeyField>}
        <ValueField>{` ${date}`}</ValueField>
      </li>
    )
  }
  return (
    <li key={uuid()}>
      {key && <KeyField>{t(key)}:</KeyField>}
      <ValueField> {t(value)}</ValueField>
    </li>
  );
};

const TaskContext = ({data, metadata, level, t}) => {
  if (Array.isArray(data) || typeof data === "object") {
    return (
      <List level={level}>
        {Object.keys(data).map((key) =>
          mapTaskContext(key, data[key], metadata, level, t)
        )}
      </List>
    );
  }

  return (
    <List level={level}>{mapTaskContext(null, data, metadata, level, t)}</List>
  );
};

TaskContext.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
  metadata: PropTypes.shape({}),
  level: PropTypes.number,
  t: PropTypes.func.isRequired,
};

TaskContext.defaultProps = {
  metadata: null,
  level: 0,
};

export default withTranslation("inbox")(TaskContext);
