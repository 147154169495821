export const dataURItoBlob = dataURI => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const fileName = dataURI.split(";")[1].split("=")[1]
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return {
    file: new Blob([ab], {type: mimeString}),
    fileName: fileName
  }
}

// Used for verifying that all specified props exist in all objects in an array.
export const allObjectsHaveOwnProps = (arrayOfObjects, props) => {
  const keys = [].concat.apply([], arrayOfObjects.map(i => Object.keys(i)))
  const lowerCaseKeys = keys.map(i => i.toLowerCase())
  return props.every(prop => {
    if (keys.includes(prop)) {
      if (arrayOfObjects.filter(i => i[prop]).length === arrayOfObjects.length) return true
      console.error("Each object in the array must contain the specified props")
      return false
    }
    else if (lowerCaseKeys.includes(prop.toLowerCase())) {
      console.error("Props exist in object, but in different letter case(s)")
      return false
    }
    return false
  })
}