import React, {Component} from "react";
import PropTypes from "prop-types";
import {PrimaryProgressButton, SecondaryProgressButton} from "@flow/buttons";
import 'bootstrap/dist/css/bootstrap.css';
import Task from "../Task";
import TaskContext from "../TaskContext";
import Form from "@rjsf/core";

class ManualBaseInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isSubmitting: false,
    }
    this.formData = this.getFormdata()
    this.formRef = React.createRef()
  }

  getFormdata() {
    const {task} = this.props
    if (task.data) return task.data
    return {}
  }

  async onFormSubmit(e, formData) {
    const {task, onComplete, close, updateCase, user} = this.props;

    if (!this.formRef.current.formElement.reportValidity()) {
      return
    }

    this.setState({isSubmitting: true})

    const dataToSubmit = {
      ...formData,
      updatedAt: new Date(),
      caseworker: {
        name: user.profile.name,
        username: user.profile.username,
        sub: user.profile.sub
      }
    }
    onComplete(task.taskId, dataToSubmit,
      () => {
        this.setState({isSubmitting: false})
        close();
        updateCase();
      });
  }

  async onFormSave(e, formData) {
    e.preventDefault()
    this.setState({isSaving: true})
    const {task, onSave, close, updateCase, user} = this.props;

    const dataToSave = {
      ...formData,
      updatedAt: new Date(),
      caseworker: {
        name: user.profile.name,
        username: user.profile.username,
        sub: user.profile.sub
      }
    }
    onSave(task.taskId, dataToSave,
      () => {
        this.setState({isSaving: false})
        updateCase();
        close();
      });
  }

  handleChange(data) {
    this.formData = data.formData
  }

  render() {
    const {task, contextMetaData, flow} = this.props
    const context = task.context;
    const {missingFieldsSchema} = flow.data
    const {isSaving, isSubmitting} = this.state
    const lastUpdated = task.data ? task.data.updatedAt.split("T").join(", ").split(".")[0].replace("Z", "").split(", ") : null

    return (
      <Task>
        <Form
          schema={missingFieldsSchema}
          formData={this.formData}
          onChange={e => this.handleChange(e)}
          ref={this.formRef}
          disabled={isSaving || isSubmitting}
          noHtml5Validate
          onSubmit={e => this.onFormSubmit(e, this.formData)}
        >
          <div className="task-handlers" style={{marginLeft: "0px"}}>
            <PrimaryProgressButton
              type="submit"
              title="Complete"
              isLoading={isSubmitting || isSaving}
            >
              Complete
            </PrimaryProgressButton>
            <SecondaryProgressButton
              title="Save and close"
              isLoading={isSubmitting || isSaving}
              onClick={e => this.onFormSave(e, this.formData)}
            >
              Save and close
            </SecondaryProgressButton>
            {lastUpdated
              ? <p style={{marginTop: "20px"}}>Last updated
                on {lastUpdated[0]} at {lastUpdated[1]} by {task.data.caseworker.username}.</p>
              : null}
          </div>
        </Form>
        <div style={{margin: "10px 20px 20px 20px", minWidth: "350px", height: "100%"}}>
          <TaskContext data={context} metadata={contextMetaData}/>
        </div>
      </Task>
    )
  }
}


ManualBaseInformation.propTypes = {
  onComplete: PropTypes.func,
  onSave: PropTypes.func,
  close: PropTypes.func,
  updateCase: PropTypes.func,
  schema: PropTypes.shape({}).isRequired,
  task: PropTypes.shape({}).isRequired,
  caseData: PropTypes.shape({}),
  t: PropTypes.func
};

ManualBaseInformation.defaultProps = {
  onComplete: () => {
  },
  onSave: () => {
  },
  close: () => {
  },
  updateCase: () => {
  },
  user: {},
  caseData: null
};

export default ManualBaseInformation;