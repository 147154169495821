import React from "react";
import styled from "styled-components";
import Logo from "./logo";

export default class MainLogo extends React.Component {
  render() {
    return (
      <MainLogoWrapper>
        <Logo />
      </MainLogoWrapper>
    );
  }
}

export const MainLogoWrapper = styled.div`
  padding-left: 32px;

  .title {
    font-family: Montserrat Alternates;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 24px;
    text-align: right;

    color: #2b2b2b;
    padding-right: 5px;
  }

  .cloud {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 24px;
    text-align: right;

    color: #d44235;
  }
`;
