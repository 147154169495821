import styled from "styled-components";

const Task = styled.div`
  height: 100%;
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-family: "IBM Plex Sans", sans-serif;
  color: rgb(108, 108, 108);
  font-size: 12px;
  line-height: 1.4em;
  > .rjsf {
    margin-left: 60px;     
    height: 100%;
  }
  fieldset {
    border: 0 none;
    > div {
      padding-top: 0.5em;
    }
  }
  input[type=text], input[type=number], input[type=date] {
    width: 350px;
    border: 1px solid rgb(184, 184, 184);
    border-radius: 0.4em;
    padding: 0.5em;
    color: rgb(43, 43, 43);
  }
  input[type=date] {
    width: auto;
  }
  textarea {
    width: 350px;
    height: 200px;
    resize: both;
  }
  > :not(:last-child) {
    border-right: 1px solid rgb(228, 226, 226);
    flex-grow: 1;
    form {
      max-width: 100%;
    }
  }
  .field-description {
    display: none;
  }
  .field-array > fieldset > legend, textarea, input[type=number] {
    margin-left: -16px;
  }
  .task-handlers, .file-upload {
    margin: 10px 0 10px -16px;
  }
  #root_anyof_select {
    display: none;
  }
  ::marker {
    display: none;
  }
  .task-handlers {
    padding-top: 4em;
  }
  .task-handlers button:nth-child(1) {
    margin-right: 2em;
  }
  .btn-add {
    width: 350px;
    height: 40px;
    flex: 1 1 0;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: bold;
  }
  .btn-add::after { 
    content: 'Add'; 
  }
  .array-item-move-up::after { 
    content: 'Move Up'; 
  }
  .array-item-move-down::after { 
    content: 'Move Down'; 
  }
  .array-item-remove {
    width: 200px;
    height: 40px;
  }
  .array-item-remove::after { 
    content: 'Remove';
  }
`

export default Task