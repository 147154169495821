import styled from "styled-components";

export default styled.div`

  .mainHeader {
    background-color: white;
  }

  .mainMenu {
    background-color: #222A35;
    
    // fixes a bug in flow portal where the navigation menu clips at 100% of initial view height
    min-height: 100vh;
    height: auto;
  }

  .mainMenuIcon.active {
    color: white;
    border-left: 3px solid white;
  }

  .activeElement {
    border-left: 2px solid blue;
  }
`;
