import React, { Component } from "react";
import styled from "styled-components";

import { Icons } from "@flow/icons";
import { Colors } from "@flow/style";

export default class Detail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  render() {
    return (
      <OuterFrame zIndex={this.props.zIndex} isMain={this.props.isMain} isOpen={this.state.isOpen}>
        <Frame onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
          {this.state.isOpen ? (
            <Icons.ChevronDown
              color={Colors.Sea}
              handleClick={() => this.setState({ isOpen: !this.state.isOpen })}
            />
          ) : (
            <Icons.ChevronRight
              color={Colors.Sea}
              handleClick={() => this.setState({ isOpen: !this.state.isOpen })}
            />
          )}
          <ContentParagraph>{this.props.t(this.props.name)}</ContentParagraph>
        </Frame>
        {this.state.isOpen ? this.props.data : ""}
      </OuterFrame>
    );
  }
}

const ContentParagraph = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: rgb(39, 39, 39);
  margin-bottom: 5px;
  margin-left: 3px;
  cursor: pointer;
`;

const OuterFrame = styled.div`
  border-top: ${props => (props.isMain ? "1px solid #eee" : "")};
  min-height: 40px;
  justify-content: center;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: ${props => (props.isMain ? "0px" : "30px")};

  transition: all 0.2s ease-out;
  background-color: ${props => (props.isOpen ? "#f8fbff" : "transparent")};

  &:hover {
    background-color: #f8fbff;
  }
`;

const Frame = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 5px;
  cursor: pointer;
`;
