import axios from "axios";
export const sendMessage = async ({ flowId, messageref, message }) => {
  try {     
    await axios.post(`/api/flow/flows/${flowId}/messages/${messageref}`, { message });
  } catch (err) {
    console.error(err)
  }
};

export const completePreviousTask = async ({ flowId, user }) => {
  try {
    let tasks = await axios.get(`/api/flow/flows/${flowId}`);
    let dueTasks = tasks.data && tasks.data.duetasks
    let task;
    if (Array.isArray(dueTasks)) {
      const pendingUploadDocuments = dueTasks.filter(task => {
        return (task.status === "pending" && task.taskType === "upload-documents")
      })
      task = pendingUploadDocuments[0]
    }

    const body = {
      caseworker: {
        name: user.profile.name,
        username: user.profile.username,
        sub: user.profile.sub
      },
      updatedAt: new Date()
    }
    if (task) await axios.post(`/api/flow/tasks/${task.taskId}/complete`, { ...body });

  } catch (err) {
    console.error(err)
  }




};