import React, { Component } from "react";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import Detail from "./Detail";
import magicDownload from "../../util/downloadLink";
import { getFile } from "../../services/files";
import { allObjectsHaveOwnProps } from "../../util/utils";

class CaseDetails extends Component {
  generateFileLines(dataItem) {
    let key = 0;
    return dataItem.map(elem => (
      <ContentGrid key={key++}>
        <div />
        <SummaryParagraph style={{ wordWrap: "break-word" }}>{`${elem.fileName}(${elem && elem.uploadedBy && elem.uploadedBy.name?elem.uploadedBy.name:elem.uploadedBy})`}</SummaryParagraph>
        <div onClick={e => this.handleAnchorClick(e, elem.storeId, elem.fileName)}>
          <SummaryParagraph style={{ wordWrap: "break-word", color: "blue", cursor: "pointer" }}>
            {" "}
            {"Click to download"}{" "}
          </SummaryParagraph>
        </div>
      </ContentGrid>
    ));
  }

  handleAnchorClick = async (e, fileId, fileName) => {
    e.preventDefault();
    try {
      getFile(fileId).then(result => {
        if(result && result.file) {
          return magicDownload(result.file, fileName);
        }
      })
    } catch (err) {
      console.error("Could not open file");
    }
  };

  generateParagraphLines(dataItem) {
    const items = [];
    const data = dataItem || {};
    let key = 0;

    Object.keys(data)
      .filter(elem => typeof data[elem] === "number" || typeof data[elem] === "string")
      .forEach(elem => {
        items.push(<SummaryParagraph key={key++}>{this.props.t(elem)}</SummaryParagraph>);
        items.push(<SummaryParagraph key={key++}>{this.props.t(data[elem])}</SummaryParagraph>);
      });

    return items;
  }

  generateDetailLines(dataItem) {
    const items = [];
    const data = dataItem || {};
    let key = 0;

    Object.keys(data).forEach(elem => {
      if (
        (data[elem] === null || Object.keys(data[elem]).length === 0) &&
        data[elem] !== 0 &&
        ((typeof data[elem] !== "boolean" && typeof data[elem] !== "object") || elem === "state")
      ) {
        items.push(
          <ContentGrid key={key++}>
            <div />
            <SummaryParagraph>{this.props.t(`${elem}`)}</SummaryParagraph>
            <SummaryParagraph> {data[elem] || "--"} </SummaryParagraph>
          </ContentGrid>
        );
      } else if (this.isJSONObject(data[elem])) {
        if (elem.toLowerCase() === "files" && allObjectsHaveOwnProps(data[elem], ["storeId", "fileName"])) {
          items.push(
            <Detail t={this.props.t || (word => word)} key={key++} name={elem} data={this.generateFileLines(data[elem])} />
          );
        } else {
          items.push(
            <Detail
              t={this.props.t || (word => word)}
              key={key++}
              name={elem}
              paragraphs={this.generateParagraphLines(data)}
              data={this.generateDetailLines(data[elem])}
            />
          );
        }
      } else {
        items.push(
          <ContentGrid key={key++}>
            <div />
            <SummaryParagraph>{this.props.t(`${elem}`)}</SummaryParagraph>
            <SummaryParagraph>
              {typeof data[elem] === "boolean"
                ? (data[elem]
                  ? this.props.t("yes")
                  : this.props.t("no"))
                : `${data[elem]}`
              }
            </SummaryParagraph>
          </ContentGrid>
        );
      }
    });

    return items;
  }

  generateSimpleDataLine(prop, value, key) {
    return <ContentGrid key={key++} style={{ "gridTemplateColumns": "32px 2fr 5fr"}}>
      <div/>
      <SummaryParagraph>{this.props.t(prop)}</SummaryParagraph>
      <SummaryParagraph style={{"wordBreak": "break-all"}}>
        {typeof value === "boolean" ? value ? this.props.t("yes") : this.props.t("no") : this.props.t(value)}
      </SummaryParagraph>
    </ContentGrid>
  }

  render() {
    return (
      <CaseDetailsWrapper>
        <Frame>
          <a href="/" ref={ref => (this.anchor = ref)} target="_blank" rel="noopener noreferrer" hidden style={{ display: "none" }}>
            DL
          </a>
          {Object.keys(this.props.data)
            .filter(elem => this.isJSONObject(this.props.data[elem]))
            .map((elem, key) => this.generateDetailComponent(elem, this.props.data, key))}
        </Frame>
        <Frame style={{ "padding": "20px 32px 20px 0", "backgroundColor": "#f8fbff", "borderTop": "1px solid #eee", "marginBottom": "50px" }}>
          {Object.keys(this.props.data)
            .filter(elem => !this.isJSONObject(this.props.data[elem]))
            .map((elem, key) => this.generateSimpleDataLine(elem, this.props.data[elem], key))}
        </Frame>
      </CaseDetailsWrapper>
    );
  }

  isJSONObject(elem) {
    return typeof elem === "object";
  }

  generateDetailComponent(elem, data, key) {
    return (
      <Detail
        t={this.props.t || (word => word)}
        key={key}
        isMain
        name={elem}
        paragraphs={this.generateParagraphLines(data)}
        data={this.generateDetailLines(data[elem])}
      />
    );
  }
}

const CaseDetailsWrapper = styled.div`
  // 225px is approximately the total height of the headers/navigations above casedetails
  height: calc(100vh - 225px);
  overflow-y: scroll;
  @media print {
    height: initial;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 32px 4fr 5fr;
  width: 100%;
`;
const Frame = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eeeeee;

`;

const SummaryParagraph = styled.p`
  font-weight: 400;
  line-height: 1.2rem;
  font-size: 15px;
  color: #777;
  font-family: "IBM Plex Sans";
  padding: 0;
  margin: 3px 0 0 5px;
`;
 
export default withTranslation("inbox")(CaseDetails);
