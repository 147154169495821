export const taskMap = (task) => {
  if (task.status === "pending" && task.taskType === "manual-verification") {
    return ({
      title: "Manually verify signees",
      actionTitle: "Open"
    });
  }
  if (task.status === "pending" && task.taskType === "manually-check-pep-hit") {
    return ({
      title: "Manually check PEP hit",
      actionTitle: "Open"
    });
  }
  if (task.status === "pending" && task.taskType === "four-eyes-verification-of-pep-hit") {
    return ({
      title: "[FOUR-EYES] - Check PEP hit",
      actionTitle: "Open",
    });
  }
  if (task.status === "pending" && task.taskType === "manually-check-risk-score") {
    return ({
      title: "Manually check risk score",
      actionTitle: "Open"
    });
  }
  if (task.status === "pending" && task.taskType === "four-eyes-verification-of-risk-score") {
    return ({
      title: "[FOUR-EYES] - Check risk score",
      actionTitle: "Open"
    });
  }
  if (task.status === "pending" && task.taskType === "manually-check-sanction-hits") {
    return ({
      title: "Manually check sanction hits",
      actionTitle: "Open"
    });
  }
  if (task.status === "pending" && task.taskType === "four-eyes-verification-of-sanction-hit") {
    return ({
      title: "[FOUR-EYES] - Check sanction hits",
      actionTitle: "Open"
    });
  }
  if (task.status === "pending" && task.taskType === "manually-check-ubo-structure") {
    return ({
      title: "Manually check beneficial owners",
      actionTitle: "Open"
    });
  }
  if (task.status === "pending" && task.taskType === "manually-verify-blacklisted-countries") {
    return ({
      title: "Manually verify blacklisted countries",
      actionTitle: "Open"
    });
  }
  if (task.status === "pending" && task.taskType === "manually-verify-origin-of-funds") {
    return ({
      title: "Manually verify origin of funds",
      actionTitle: "Open"
    });
  }
  if (task.status === "pending" && task.taskType === "accept-customer") {
    return ({
      title: "Accept customer",
      actionTitle: "Open"
    })
  }
  if (task.status === "pending" && task.taskType === "gather-base-information") {
    return ({
      title: "Fill in basic information",
      actionTitle: "Open"
    })
  }
  if (task.status === "pending" && task.taskType === "upload-documents") {
    return ({
      title: "Awaiting additional documentation from client",
      actionTitle: "Open",
    })
  }

  if (task.status === "pending" && task.taskType === "manually-request-additional-information") {
    return ({
      title: "Request information from client",
      actionTitle: "Open",
    })
  }
  return ({
    title: task.taskType,
    actionTitle: "Open"
  });
}
