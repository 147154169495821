import React from "react";
import styled from "styled-components";
import { fileLocator } from "../helpers/fileLocator";
import { DownloadFileButton, Collapsible } from "@flow/components";

const FileTab = ({ flow, t : translate }) => {
    const files = fileLocator(flow);
    var groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
    const categories = groupBy(files, "category");
    const compoents = [];
    for (const category in categories) {
        compoents.push(<Collapsible header={<Category>{category}</Category>}>
        {categories[category].map((file, index) => (
            <Container>
                <Collapsible header={file.fileName}>
                    <Container>
                        {file.uploadedBy && <FileDetails><p>Uploaded by:</p> {file.uploadedBy}</FileDetails>}
                        <FileDetails><p>Source:</p>{file.source}</FileDetails>
                        <DownloadFileButton fileId={file.storeId} ></DownloadFileButton>
                    </Container>
                </Collapsible>
            </Container>
        ))}
        </Collapsible>);
    }

    return (<div>
        {compoents}
    </div>
    );
}

const Category = styled.h1`
    font-weight: 500;
    font-size: 16px;
    color: rgb(39,39,39);
    margin-bottom: 5px;
    margin-left: 3px;
    cursor: pointer;
    padding: 10px;
    border-top: 1px solid #eeeeee;
    display: block;
    width:100%
`;

const FileDetails = styled.div`
    font-size: 14px;
    color: darkgray;
    margin-bottom: 4px;
    display: grid;
    grid-template-columns: 160px auto;
`;

const Container = styled.div`
    margin: 10px;
`;

export default FileTab;