import React from "react";

export default class Logo extends React.Component {
  render() {
    return (
      <div style={{ width: "60px", height: "40px" }}>
        <svg width="60" height="40" viewBox="0 0 258 200" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M178.279 144.299V31.2767H206.324V144.299C206.324 161.6 212.801 167.945 230.763 167.945H253.6L243.183 196H230.763C214.273 196 201.25 191.402 192.302 182.766C183.353 191.402 170.33 196 153.84 196C136.727 196 123.348 191.049 114.382 181.768C110.858 185.008 106.792 187.731 102.269 189.926C96.7253 192.617 90.9331 194.304 85.1654 195.237C83.1511 195.563 81.2648 195.774 79.5392 195.893C78.4164 195.971 77.531 196 76.9169 196H8.42267L44.4805 102.216H4L14.4167 74.1609H85.3147L49.2569 167.945H76.8924C78.5036 167.843 79.547 167.726 80.6879 167.541C83.9349 167.016 87.1574 166.078 90.027 164.685C97.3906 161.111 101.346 155.374 101.357 144.699C101.357 144.622 101.327 139.812 101.269 130.271L101.588 4L129.633 4.02319C129.633 31.4118 129.626 54.7908 129.613 74.1609L129.585 102.216C129.582 104.669 129.578 107.014 129.575 109.253C129.555 120.957 129.531 129.737 129.502 135.601C129.488 138.552 129.472 140.77 129.454 142.269C129.445 143.056 129.436 143.658 129.425 144.098C129.418 144.38 129.411 144.66 129.404 144.938C129.564 161.757 136.101 167.945 153.84 167.945C171.802 167.945 178.279 161.6 178.279 144.299V31.2767H206.324V144.299" fill="#222A35"/>
          <path d="M122.427 74.4H148L137.554 102.4H122.4L122.427 74.4Z" fill="#222A35"/>
          <path d="M107.173 102.4H81.6L92.0464 74.4H107.2L107.173 102.4Z" fill="#222A35"/>
          <path d="M150.476 74.4H158.308L147.859 102.4H140L150.476 74.4ZM160.931 74.4H174.4L163.952 102.4H150.455L160.931 74.4Z" fill="url(#paint0_linear)"/>
          <defs>
            <linearGradient id="paint0_linear" x1="168.866" y1="93.0855" x2="143.959" y2="81.0358" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFA900"/>
              <stop offset="1" stopColor="#510000"/>
            </linearGradient>
          </defs>
        </svg>


      </div>
    );
  }
}
