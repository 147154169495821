import axios from "axios";

export const getFile = async (fileId) => {
  const filePath = `/api/files/${fileId}`;

  try {
    const res = await axios({
      method: "get",
      url: filePath,
      responseType: "arraybuffer"
    });

    const type = res.headers["content-type"];
    if (type.includes("image") || type.includes("pdf")) {
      return {file: res.data, type};
    }

    const error = new Error(`Error while getting file. Unexpected content-type: ${type}`);
    console.error(error);
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const postFile = async formData => {
  try {
    return axios.post(`/api/files`, formData);
  } catch (err) {
    console.error("Something went wrong uploading files", err.message)
    return null;
  }
};

export const deleteFile = async storeId => {
  try {
    return axios.delete(`/api/files/${storeId}`);
  } catch (err) {
    console.error(`Something went wrong deleting file ${storeId}`, err.message)
    return null;
  }
};

export const getFileMetadata = async (fileId) => {
  const filePath = `/api/files/${fileId}/meta`;

  try {
    return await axios.get(filePath);
  } catch (error) {
    console.error(error);
    return null;
  }
};