import React from "react";
import { Icons } from "@flow/icons";

export default class CustomerIcon extends React.Component {
  render() {
    return (
      <div>
        <Icons.StaccIcon />
      </div>
    );
  }
}
