export const fileLocator = ( flow ) => {

    // When files are available on path /files
    if (flow.data.files)
        return [...flow.data.files];

    // Files that are scathered around on state
    let pepFiles = [];
    let sanctionFiles = [];
    let UBOfiles = [];
    let originOfFundsFiles = [];
    if (flow.data.manualAssessments) {
        const pep = flow.data.manualAssessments.pepHits && flow.data.manualAssessments.pepHits.files;
        if (pep) {
            pepFiles = pep.map(file => {
                return {
                    category: "PEP",
                    fileName: file.fileName,
                    storeId: file.storeId,
                    uploadedBy: flow.data.manualAssessments.pepHits.caseworker.name,
                    source: "Caseworker"
                }
            });
        }
        const sanctions = flow.data.manualAssessments.sanctionHits && flow.data.sanctionHits.files;
        if (sanctions) {
            sanctionFiles = pep.map(file => {
                return {
                    category: "Sanctions",
                    fileName: file.fileName,
                    storeId: file.storeId,
                    uploadedBy: flow.data.sanctionHits.caseworker.name,
                    source: "Caseworker"
                }
            });
        }
        const ubo = flow.data.manualAssessments.uboStructure && flow.data.manualAssessments.uboStructure.files;
        if (ubo) {
            UBOfiles = ubo.map(file => {
                return {
                    category: "UBO structure",
                    fileName: file.fileName,
                    storeId: file.storeId,
                    uploadedBy: flow.data.manualAssessments.uboStructure.caseworker.name,
                    source: "Caseworker"
                }
            });
        }
        const originOfFunds = flow.data.manualAssessments.originOfFunds && flow.data.manualAssessments.originOfFunds.files;
        if (originOfFunds) {
            originOfFundsFiles = originOfFunds.map(file => {
                return {
                    category: "Origin of Funds",
                    fileName: file.fileName,
                    storeId: file.storeId,
                    uploadedBy: flow.data.manualAssessments.originOfFunds.caseworker.name,
                    source: "Caseworker"
                }
            });
        }
    }
    const signedDocuments = flow.data.signeeInformation ? flow.data.signeeInformation.fileStorageDocument : [];
    const signedDocumentsFiles = signedDocuments.map(file => {
        return {
            category: "Signed Documents",
            fileName: file.filename,
            storeId: file.storeID,
            source: "Signicat"
        }
    });
    return [...pepFiles, ...sanctionFiles, ...UBOfiles, ...originOfFundsFiles, ...signedDocumentsFiles];
};