import React, { Component } from "react";
import PropTypes from "prop-types";
import { PrimaryProgressButton, SecondaryProgressButton } from "@flow/buttons";
import 'bootstrap/dist/css/bootstrap.css';
import Form from "@rjsf/core"
import { withTranslation } from "react-i18next";

import Task from "../Task";
import TaskContext from "../TaskContext";

import { sendMessage, completePreviousTask } from "../../services/process";

const metaSchemaDraft06 = require("ajv/lib/refs/json-schema-draft-06.json");
class ManualRequestInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      isSaving: false,
      isSubmitting: false,
      UBO: false,
      SANCTION: false,
      PEP: false,
      ORIGINOFFUNDS: false,
      RISK: false,
      text: ""
    }
  }

  async onFormSubmit(e, formData) {
    e.preventDefault()
    const { task, onComplete, close, updateCase, user, context, caseData: { data } } = this.props;
    const { flowId } = data

    this.setState({ isSubmitting: true })
    await completePreviousTask({ flowId, recepient: { ...context }, user: this.props.user })

    const dataToSubmit = {
      ...formData,
      updatedAt: new Date(),
      caseworker: {
        name: user.profile.name,
        username: user.profile.username,
        sub: user.profile.sub
      }
    }
    onComplete(task.taskId, dataToSubmit,
      () => {
        this.setState({ isSubmitting: false })
        close();
        updateCase();
      });
  }

  onSignersChange(e, signers) {
    e.preventDefault()
    const signer = signers.filter(signer => signer.name === e.target.value)[0]
    this.setState({ signer: signer })
    this.setCheckbox(signer)
  }

  handleCheckboxChange(evt, topic) {
    const s = {}
    s[topic] = evt.target.checked
    this.setState(s);
  }

  handleChange(evt, topic) {
    const s = {}
    s[topic] = evt.target.value
    this.setState(s);
  }

  formatSentEmails({ emails }) {
    let arr = []

    emails.forEach(e => {
      const c = e.id
      const obj = {};
      obj[`${c}`] = {
        ...e
      }
      arr.push(obj)
    });

    return { messagesSent: arr }
  }

  getTopics() {
    const topics = ["UBO", "PEP", "ORIGINOFFUNDS", "SANCTION", "RISK"]
    const r = []
    for (const [key, value] of Object.entries(this.state)) {
      if (topics.includes(key) && value) r.push(key)
    }
    return r
  }

  populateStateWithExistingFileRequest({ context }) {
    if (context && context.files && context.files.fileRequests) {
      const keys = Object.keys(context.files.fileRequests)
      keys.forEach(key => {
        const p = {}
        p[key] = true
        this.setState(p)
      });
    }
  }


  componentDidMount() {
    this.populateStateWithExistingFileRequest({ context: this.props.context })
  }

  render() {
    const { schema, context, contextMetaData, caseData: { data }, close } = this.props
    this.getTopics()

    let email = (data && data.email && data.email.sent) || []
    const emailFormatted = {}
    if (email.length > 0) {
      email.forEach(em => {
        const index = email.findIndex(e => e.sent === em.sent)
        const times = em.sent.replace("Z", "").split(".")[0].split("T")
        const header = `(${index + 1}) - ${em.topics.join()}`
        emailFormatted[`${header}`] = { sent: times[0], isPending: false, request: em.text, response: em.response ? em.response : "No response" }
      });
    }
    if (context.files && context.files.fileRequests) {
      const key = Object.keys(emailFormatted).pop()
      emailFormatted[key].isPending = true
    }
    const messsages = { messages: emailFormatted }

    var modifiedContext = JSON.parse(JSON.stringify(context));
    if (modifiedContext.files && modifiedContext.files.fileRequests) {
      delete modifiedContext.files.fileRequests
      const keys = Object.keys(modifiedContext.files)
      keys.forEach(key => {
        modifiedContext.files[key].files.forEach(element => {
          delete element.keep
          delete element.uploadedBy.id
        });
      });
    }



    const { flowId } = data
    const { isSaving, isSubmitting } = this.state
    const { uiSchema } = schema
    return (
      <Task>
        <Form
          schema={schema}
          uiSchema={uiSchema}
          formData={this.formData}
          ref={this.formRef}
          disabled={isSaving || isSubmitting}
          additionalMetaSchemas={[metaSchemaDraft06]}
          noHtml5Validate
        >
          <form style={{ marginRight: "60px" }}>
            <div class="form-group">
              <label for="exampleFormControlSelect2">What files do we require?</label>
              <div class="form-check" id="exampleFormControlSelect2">
                <input class="form-check-input" type="checkbox" id="checkUBO" checked={this.state.UBO} onChange={e => this.handleCheckboxChange(e, "UBO")} />
                <label class="form-check-label" for="defaultCheck1"> Ultimate Beneficial Owner(UBO) </label>
              </div>
              <div class="form-check" id="exampleFormControlSelect3">
                <input class="form-check-input" type="checkbox" id="checkOriginOfFunds" checked={this.state.ORIGINOFFUNDS} onChange={e => this.handleCheckboxChange(e, "ORIGINOFFUNDS")} />
                <label class="form-check-label" for="defaultCheck2">
                  Origin of funds
          </label>
              </div>
              <div class="form-check" id="exampleFormControlSelect4">
                <input class="form-check-input" type="checkbox" id="checkPEP" checked={this.state.PEP} onChange={e => this.handleCheckboxChange(e, "PEP")} />
                <label class="form-check-label" for="defaultCheck4">
                  Politically Exposed Person(PEP)
          </label>
              </div>
              <div class="form-check" id="exampleFormControlSelect5">
                <input class="form-check-input" type="checkbox" id="checkSanction" checked={this.state.SANCTION} onChange={e => this.handleCheckboxChange(e, "SANCTION")} />
                <label class="form-check-label" for="defaultCheck5">
                  Sanction
          </label>
              </div>
              <div class="form-check" id="exampleFormControlSelect6">
                <input class="form-check-input" type="checkbox" id="checkRisk" checked={this.state.RISK} onChange={e => this.handleCheckboxChange(e, "RISK")} />
                <label class="form-check-label" for="defaultCheck6">
                  Risk
          </label>
              </div>
            </div>
            <div class="form-group">
              <label for="exampleFormControlTextarea1">Write additional text</label>
              <textarea class="form-control" style={{ marginLeft: "0px", width: "100%" }} id="exampleFormControlTextarea1" rows="5" value={this.state.text} onChange={e => this.handleChange(e, "text")}></textarea>
            </div>
            <span style={{ display: "flex", minWidth: "100%" }}>
              <SecondaryProgressButton isLoading={isSubmitting || isSaving} disabled={!this.state.SANCTION && !this.state.UBO && !this.state.PEP && !this.state.ORIGINOFFUNDS && !this.state.RISK} onClick={async () => {
                this.setState({ isSubmitting: true })
                await completePreviousTask({ flowId, recepient: { ...context }, user: this.props.user })
                await sendMessage({ flowId, messageref: "message-receiver-req-docs", message: { recepient: { email: context.contact.contactPersonEmail }, topics: this.getTopics(), text: this.state.text } })
                this.setState({ isSubmitting: false })
                close();
              }}>
                Send message
            </SecondaryProgressButton>
              <PrimaryProgressButton
                style={{ marginLeft: "auto" }}
                title="Complete"
                isLoading={isSubmitting || isSaving}
                onClick={e => this.onFormSubmit(e, this.formData)}
              >Complete</PrimaryProgressButton>
            </span>
            <div class="form-group" style={{ marginTop: 16 }}>
              <div>
                <TaskContext data={messsages} />
              </div>
            </div>
          </form>
        </Form>
        <div style={{ margin: "10px 20px 20px 20px", "minWidth": "350px", "maxWidth": "40%", height: "100%" }}>
          <TaskContext data={modifiedContext} metadata={contextMetaData} />
        </div>
      </Task>
    )
  }
}


ManualRequestInformation.propTypes = {
  onComplete: PropTypes.func,
  onSave: PropTypes.func,
  close: PropTypes.func,
  updateCase: PropTypes.func,
  schema: PropTypes.shape({}).isRequired,
  context: PropTypes.shape({}).isRequired,
  task: PropTypes.shape({}).isRequired,
  caseData: PropTypes.shape({}),
  t: PropTypes.func
};

ManualRequestInformation.defaultProps = {
  onComplete: () => {
  },
  onSave: () => {
  },
  close: () => {
  },
  updateCase: () => {
  },
  user: {},
  caseData: null
};

export default withTranslation("inbox")(ManualRequestInformation);