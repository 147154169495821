import React, {Component} from "react";
import PropTypes from "prop-types";
import {PrimaryProgressButton, SecondaryProgressButton} from "@flow/buttons";
import 'bootstrap/dist/css/bootstrap.css';
import styled from "styled-components";
import Form from "@rjsf/core"
import {Validator} from "jsonschema"
import {deleteFile, getFile, getFileMetadata, postFile} from "../../services/files";
import magicDownload from "../../util/downloadLink"; 
import { withTranslation } from "react-i18next";

import Task from "../Task";
import TaskContext from "../TaskContext";

const metaSchemaDraft06 = require("ajv/lib/refs/json-schema-draft-06.json");

export const mapSchemaErrors = errors => {
  return errors.map(err => {
    if (err.property.endsWith("postalCode")) {
      if (err.name === "maxLength" || err.name === "minLength")
        return "Postal code should be exactly 4 digits long."
      else if (err.name === "pattern")
        return "Postal code should be all digits."
      return `Unhandled error on field ${err.property}: ${err.message}`
    }

    if (err.property.endsWith("orgNo")) {
      if (err.name === "maxLength" || err.name === "minLength")
        return "Organization number should be exactly 9 digits long."
      else if (err.name === "pattern")
        return "Organization number should be all digits."
      return `Unhandled error on field ${err.property}: ${err.message}`
    } else if (err.property === "files")
      return `This task requires you to upload relevant documentation.`

    else if (err.property.endsWith("relationToCustomer")) {
      if (err.name === "maxLength")
        return "Relation to customer can be maximum 100 characters."
      return `Unhandled error on field ${err.property}: ${err.message}`
    }

    if (err.property.endsWith("persons")) {
      if (err.name === "minItems")
        return "You must add at least one person to the form."
    }

    if (err.property.endsWith("companies")) {
      if (err.name === "minItems")
        return "You must add at least one company to the form."
    }

    return `Unhandled form error on field ${err.property}: ${err.message}`
  })
}

const DownloadButton = styled.button`
  background-color: #ffba30;
  color: white;
  width: 200px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #ffba30;
  border-radius: 4px;
  cursor: pointer;
  :focus, :hover {
    background-color: white;
    color: #ffba30;
  }
`

const InputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  border-radius: 4px;
  + label {
    font-weight: 700;
    color: rgb(255, 186, 48);
    background-color: white;
    display: inline-block;
    cursor: pointer;
    border: 1px solid rgb(255, 186, 48);
    border-radius: 4px;
    padding: 5px;
    text-align: center;
  }
  :focus + label, + label:hover {
    background-color: rgb(255, 186, 48);
    color: white;
  } 
`

class ManualHandling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isSubmitting: false,
    }
    this.formData = this.getFormdata()
    if (this.formData.files) {
      this.uploadedFiles = [...this.formData.files] // files to be displayed
      delete this.formData.files
    }
    this.formRef = React.createRef()
  }

  getFormdata() {
    const {task, taskType, caseData} = this.props
    if (task.data) return task.data

    const tasksWithFoureyes = ["manually-check-pep-hit", "manually-check-risk-score", "manually-check-sanction-hits"]
    if (tasksWithFoureyes.some(tt => tt === taskType) && caseData.data && caseData.data.manualAssessments) {
      const {manualAssessments} = caseData.data
      if (taskType === "manually-check-pep-hit" && manualAssessments.pepHits) return manualAssessments.pepHits
      if (taskType === "manually-check-sanction-hits" && manualAssessments.sanctionHits) return manualAssessments.sanctionHits
      if (taskType === "manually-check-risk-score" && manualAssessments.riskScore) return manualAssessments.riskScore
      return {}
    }

    return {}
  }

  async onFileUpload(files) {
    const upload = async file => {
      const form = new FormData();
      form.append("file", file, file.name)
      const {data} = await postFile(form)
      return {
        fileName: file.name,
        storeId: data.id,
        keep: true
      }
    }
    return await Promise.all(files.map(f => (upload(f))))
  }

  willDeleteAllFiles(files) {
    return files.every(file => !file.keep)
  }

  addErrorList(errors) {
    const mappedErrors = mapSchemaErrors(errors)
    const item = document.createElement("p")
    item.classList.add("error-list")
    item.style.padding = "10px 10px 10px 0"
    item.style.fontSize = "15px"
    item.style.color = "red"
    item.innerHTML = mappedErrors.join("<br>")
    document.getElementsByClassName("task-handlers")[0].prepend(item)
  }

  async onFormSubmit(e, formData) {
    e.preventDefault()
    const {task, onComplete, close, updateCase, user} = this.props;

    const errors = this.validateFormData(formData)

    // indicates if a required field is missing, but does not capture i.e. field patterns or constraints
    if (!this.formRef.current.formElement.reportValidity()) {
      return
    }

    if (this.taskRequiresFileUploads()) {
      // if there are no files to be uploaded or files previously uploaded and saved
      if (!(formData && formData.files) && !(this.uploadedFiles)) {
        errors.push({property: "files"})
      }
      // if all previously uploaded and saved files are checked to be deleted, and there are no new files to be uploaded
      else if (this.uploadedFiles && this.willDeleteAllFiles(this.uploadedFiles) && !formData.files) {
        errors.push({property: "files"})
      }
    }
    if (errors.length) {
      const errorList = document.getElementsByClassName("error-list")
      if (errorList.length > 0) errorList[0].remove()
      this.addErrorList(errors)
      return
    }

    this.setState({isSubmitting: true})

    formData.files = await this.updateFiles(formData)
    if (formData.files.length === 0) delete formData.files

    const dataToSubmit = {
      ...formData,
      updatedAt: new Date(),
      caseworker: {
        name: user.profile.name,
        username: user.profile.username,
        sub: user.profile.sub
      }
    }
    onComplete(task.taskId, dataToSubmit,
      () => {
        this.setState({isSubmitting: false})
        close();
        updateCase();
      });
  }

  async onFormSave(e, formData) {
    e.preventDefault()
    this.setState({isSaving: true})
    const {task, onSave, close, updateCase, user} = this.props;
    formData.files = await this.updateFiles(formData)
    if (formData.files.length === 0) delete formData.files

    const dataToSave = {
      ...formData,
      updatedAt: new Date(),
      caseworker: {
        name: user.profile.name,
        username: user.profile.username,
        sub: user.profile.sub
      }
    }
    onSave(task.taskId, dataToSave,
      () => {
        this.setState({isSaving: false})
        updateCase();
        close();
      });
  }

  async updateFiles(formData) {
    let files = this.uploadedFiles && this.uploadedFiles ? this.uploadedFiles : []
    const newFiles = formData && formData.files ? formData.files.filter(f => !f.hasOwnProperty("storeId")) : []

    // upload new files
    let uploaded = []
    if (newFiles) {
      const newF = await this.onFileUpload(newFiles)
      uploaded.push(...newF)
    }

    // delete selected old files
    let finalFilesCollection = []
    for (const file of files) {
      if (file.keep) {
        finalFilesCollection.push(file)
        continue
      }
      await deleteFile(file.storeId)
    }

    finalFilesCollection.push(...uploaded)
    return finalFilesCollection;
  }

  handleFileSelect(e) {
    this.formData.files = Array.from(e.target.files)
    const fileListNode = document.getElementById("file-list")
    fileListNode.innerHTML = this.formData.files.map(i => (i.name)).join("<br>")
  }

  onDownloadClick(e, fId) {
    e.preventDefault();

    getFileMetadata(fId).then((response) => {
      if (!response) {
        console.error("Error getting file meta data");
        return;
      }

      const metadata = response.data;
      const {filename} = metadata;

      getFile(fId).then(result => {
        if (result && result.file) {
          return magicDownload(result.file, filename);
        }
        return null;
      });
    });
  }

  handleChange(data) {
    const files = this.formData.files
    this.formData = data.formData
    if (files && files.length > 0) this.formData.files = files
  }

  handleCheckedDeleteFile(e) {
    this.uploadedFiles.find(f => f.storeId === e.target.id).keep = !this.uploadedFiles.find(f => f.storeId === e.target.id).keep
  }

  taskRequiresFileUploads() {
    const tasksRequiringFileUploads = [
      "manually-verify-origin-of-funds",
      "manually-check-sanction-hits",
      "manually-check-pep-hit",
      "manually-check-ubo-structure",
    ]
    return tasksRequiringFileUploads.some(t => t === this.props.task.taskType)
  }

  taskRequiresOptionalFileUploads() {
    return this.props.task.taskType === "manual-verification";
  }

  validateFormData(formData) {
    const validator = new Validator()
    const {schema} = this.props
    return validator.validate(formData, schema).errors
  }

  render() {
    const {schema, task, contextMetaData} = this.props
    const {isSaving, isSubmitting} = this.state
    const {uiSchema} = schema
    const lastUpdated = task.data ? task.data.updatedAt.replace("Z", "").split(".")[0].split("T") : null
    return (
      <Task>
        <Form
          schema={schema}
          uiSchema={uiSchema}
          formData={this.formData}
          onChange={e => this.handleChange(e)}
          ref={this.formRef}
          disabled={isSaving || isSubmitting}
          additionalMetaSchemas={[metaSchemaDraft06]}
          noHtml5Validate
        >
          {this.taskRequiresFileUploads() || this.taskRequiresOptionalFileUploads() ? <div className="file-upload">
          <InputFile id="file" name="file" type="file" accept="image/png, image/jpeg, application/pdf"
                       multiple
                       onChange={e => this.handleFileSelect(e)}
                       disabled={isSaving || isSubmitting}/>
            <label htmlFor="file">
              { this.taskRequiresFileUploads() ? "Upload documentation*" : "Upload documentation" }
            </label>
            <b><div id="file-list"/></b>
            <br></br>
            <p>File(s) uploaded must be one of the following types: <b>png</b>, <b>pdf</b>, <b>jpeg</b></p>
          </div> : null}

          {this.uploadedFiles && this.uploadedFiles && this.uploadedFiles && this.uploadedFiles.length > 0 ?
            <table className="file-upload">
              <thead>
              <tr>
                <th>Files previously uploaded</th>
                <th>Delete?</th>
              </tr>
              </thead>
              <tbody>
              {this.uploadedFiles.map(f => {
                return <tr key={f.storeId}>
                  <td>
                    <DownloadButton disabled={isSaving || isSubmitting}
                                    onClick={e => this.onDownloadClick(e, f.storeId)}>{f.fileName}</DownloadButton>
                  </td>
                  <td>
                    <input type="checkbox" id={f.storeId} onChange={e => this.handleCheckedDeleteFile(e)}
                           disabled={isSaving || isSubmitting} style={{cursor: "pointer"}}/>
                  </td>
                </tr>
              })}
              </tbody>
            </table> : null}
          <div className="task-handlers">
            <PrimaryProgressButton
              title="Complete"
              isLoading={isSubmitting || isSaving}
              onClick={e => this.onFormSubmit(e, this.formData)}
            >
              Complete
            </PrimaryProgressButton>
            <SecondaryProgressButton
              title="Save and close"
              isLoading={isSubmitting || isSaving}
              onClick={e => this.onFormSave(e, this.formData)}
            >
              Save and close
            </SecondaryProgressButton>
            {lastUpdated
              ? <p style={{"margin-top": "20px"}}>Last updated
                on {lastUpdated[0]} at {lastUpdated[1]} by {task.data.caseworker.username}.</p>
              : null}
          </div>
        </Form>
        <div style={{margin: "10px 20px 20px 20px", "minWidth": "350px", "maxWidth": "40%", height: "100%"}}>
          <TaskContext data={task.context} metadata={contextMetaData}/>
        </div>
      </Task>
    )
  }
}


ManualHandling.propTypes = {
  onComplete: PropTypes.func,
  onSave: PropTypes.func,
  close: PropTypes.func,
  updateCase: PropTypes.func,
  schema: PropTypes.shape({}).isRequired,   
  task: PropTypes.shape({}).isRequired,
  caseData: PropTypes.shape({}),
  t: PropTypes.func
};

ManualHandling.defaultProps = {
  onComplete: () => {
  },
  onSave: () => {
  },
  close: () => {
  },
  updateCase: () => {
  },
  user: {},
  caseData: null
};

export default withTranslation("inbox")(ManualHandling);