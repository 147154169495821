import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Colors, FontStyles } from "@flow/style";
import { format, parseISO } from "date-fns";

const QueueItem = ({ path, flow, t }) => {
  const { referenceId, flowDefinitionId, flowId, data, createdAt, assignee } = flow;
  const createdAtDate = format(parseISO(createdAt), "yyyy-MM-dd");
  if (flowDefinitionId === "onboarding") {
    const { organizationId, partnerId } = data
    return (
      <ItemNavLink
        activeClassName="active"
        to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}
      >
        <Left style={{ "borderLeft": assignee ? "10px solid #ffc107" : "10px solid #212529", "paddingLeft": "10px" }}>
          <p>{t(flowDefinitionId)} - {partnerId}</p>
          <Title>{organizationId || "Unspecified"}</Title>
          <SmallTitle>{data.raw?.bisnode.NavnAdresse.Navn || "Unspecified"}</SmallTitle>
        </Left>
        <Right>
          <SmallTitle>{createdAtDate}</SmallTitle>
          <SmallTitle style={{paddingTop: "3px"}}>{referenceId}</SmallTitle>
        </Right>
      </ItemNavLink>
    );
  }
  if (flowDefinitionId === "payment" || flowDefinitionId === "all-payments") {
    const { partnerId, organizationId } = data.input || {}
    const paymentStatus = data && data.paymentStatus ? t(data.paymentStatus) : "Status unknown"
    return (
      <ItemNavLink
        activeClassName="active"
        to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}
      >
        <Left>
          <p>{t(flowDefinitionId)} - {partnerId || "Unspecified"}</p>
          <Title>{organizationId || "Unspecified"}</Title>
          <SmallTitle>{paymentStatus}</SmallTitle>
        </Left>
        <Right>
          <SmallTitle>{createdAtDate}</SmallTitle>
          <SmallTitle style={{paddingTop: "3px"}}>{referenceId}</SmallTitle>
        </Right>
      </ItemNavLink>
    );
  }
  return (
    <ItemNavLink
      activeClassName="active"
      to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}
    >
      <Left>
        <p>{t(flowDefinitionId)}</p>
        <Title>{referenceId}</Title>
      </Left>
      <Right>
        <SmallTitle>{createdAtDate}</SmallTitle>
      </Right>
    </ItemNavLink>
  );
};

QueueItem.propTypes = {
  path: PropTypes.string.isRequired,
  flow: PropTypes.shape({
    flowDefinitionId: PropTypes.string.isRequired,
    flowId: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    referenceId: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired
  }),
  t: PropTypes.func.isRequired
};

QueueItem.defaultProps = {
  flow: {}
};

const ItemNavLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid ${Colors.Grey3};
  ${FontStyles.Small};
  padding: 10px;
  &.active {
    background-color: ${Colors.SeaLighter};
    pointer-events: none;
  }
  :hover {
    color: inherit;
  }
`;

const Title = styled.p`
  ${FontStyles.LargeStrong};
`;

const SmallTitle = styled.p`
  ${FontStyles.Small};
`

const Left = styled.div`
  > :first-child {
    padding-top: 1px;
    padding-bottom: 1px;
  }
`;
const Right = styled.div`
  > :first-child {
    padding-top: 1px;
    padding-bottom: 1px;
  }
`;

export default QueueItem;